@import "../theme/theme";

.card {
  height: fit-content;
  min-width: 500px;
  .meta {
    h5 {
      font-weight: 500;
      color: $gray0;
    }
  }
  .cardContent {
    border: 1px solid $gray7;
    border-radius: 4px;
    ul {
    }
  }
}
