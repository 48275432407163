@import "../theme/theme";

.card-contents {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 32px;

  .full-length {
    grid-column: 1/4;
    ul {
      padding: 0;
      margin: 0;
    }
    ul.applicationsList {
      li:last-child() {
        border: none;
      }
    }
  }
  .one-thirds {
    grid-column: 1/2;
  }
  .two-thirds {
    grid-column: 1/3;
  }

  .performance-analytics {
    .cardContent {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 32px;
      padding: 0 8px 32px;
      .cardContent {
        display: unset;
        grid-template-columns: unset;
        grid-column-gap: unset;
        padding: unset;
      }
    }
  }
}
