@import "../theme/theme";

.NavBar {
  display: flex;
  flex-direction: column;
  width: 224px;
  padding: 8px;

  background: $gray9;

  font-family: $core-font;

  .logo {
    display: flex;
    justify-content: center;
    svg {
      fill: #36393a;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a.active div.NavButton {
    background: $gray8;
    border-radius: 4px;
  }
}

.NavButton {
  display: flex;
  width: 100%;
  padding: 8px 0 8px 8px;
  color: $gray4;
  cursor: pointer;

  svg {
    fill: $blue4;
  }

  .Label {
    display: block;
    padding: 0 0 0 8px;
    font-size: 13px;
  }
}
.NavButton:hover {
  background: $gray4;
  color: $gray9;
  border-radius: 4px;
  svg {
    fill: $blue9;
  }
}

a.active div.NavButton:hover {
  color: $gray4;
  svg {
    fill: $blue4;
  }
}

.search {
  margin: 24px 0 0 0;
}
.search:hover {
  cursor: text;
  outline: 1px solid $gray8;
  outline-offset: -2px;
  background: unset;
  color: $gray4;
  svg {
    fill: $blue4;
  }
}

.title {
  cursor: unset;
  padding: 24px 0 8px 0;
  color: $gray4;
}
.title:hover {
  background: unset;
  color: $gray4;
}

.sansIcon {
  padding: 8px 0 8px 24px;
}
