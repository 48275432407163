@import "../theme/theme";

.layout {
  display: flex;
  height: 100vh;
  width: 100%;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px;
  }
}
