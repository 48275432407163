// theme/_fonts.scss

// @import url('https://fonts.googleapis.com/css?family=Raleway:800|Merriweather+Sans|Share+Tech+Mono');

// $logo-font: 'Share Tech Mono', monospace;
// $header-font: 'Raleway', sans-serif;
// $core-font: 'Merriweather Sans', sans-serif;

$logo-font: -apple-system, system-ui, BlinkMacSystemFont, \\"Segoe UI\\", Roboto,
  Oxygen, Ubuntu, \\"Helvetica Neue\\", Arial, sans-serif;
$header-font: -apple-system, system-ui, BlinkMacSystemFont, \\"Segoe UI\\",
  Roboto, Oxygen, Ubuntu, \\"Helvetica Neue\\", Arial, sans-serif;
$core-font: -apple-system, system-ui, BlinkMacSystemFont, \\"Segoe UI\\", Roboto,
  Oxygen, Ubuntu, \\"Helvetica Neue\\", Arial, sans-serif;
