@import "../theme/theme";

a,
a:visited {
  color: unset;
  text-decoration: none;
}

li.tableItems {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 32px;
  span {
  }
}
li.tableItems {
  padding: 8px;
  list-style: none;
  border-bottom: 1px solid $gray7;
}
li.tableItems:hover {
  background-color: $gray4;
  color: $gray9;
  cursor: pointer;
}
