@import "../theme/theme";

.searchModal {
  position: absolute;
  top: calc(25% - 160px);
  left: calc(50% - 320px);
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 640px;
  height: 320px;
  background: #ffffff;
  // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  //   0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.45);
  z-index: 999;
  .inputContainer {
    padding: 8px;
    input {
      width: 100%;
      height: 64px;
      font-size: 20px;
      border: none;
      padding: 0 8px;
    }
    input:focus {
      outline: none !important;
      border: 1px solid #f6821f;
      border-radius: 4px;
    }
  }
  .results {
    border-top: 1px solid $gray7;
    margin: 8px 0 0 0;
    padding: 0;
    ul {
      padding: 0 0;
      margin: 0;
      li {
        font-size: 20px;
        list-style: none;
        padding: 8px 16px;
        margin: 0;
      }
    }
  }
}
