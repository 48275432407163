// theme/_colors.scss

$blue4: #0051c3;
$blue9: #ecf4ff;

$gray0: #1d1d1d;
$gray1: #313131;
$gray2: #3d3d3d;
$gray3: #4a4a4a;
$gray4: #595959;
$gray5: #797979;
$gray6: #999999;
$gray7: #b6b6b6;
$gray8: #d9d9d9;
$gray9: #f2f2f2;

$primary: #ffffff;
$primary-light: #f2f2f2;
$primary-dark: #1d1f20;

$secondary: #2a3747;
$secondary-light: #f2f2f2;
$secondary-dark: #191b1d;

$tertiary-light: #546172;
$tertiary-dark: #161b22;

$on-primary: #000;
$on-primary-light: #a1a1a1;
$on-secondary: #fff;

$highlight: #fa4141;
